<script>
    import {performRegistrationCeremony} from "~/common/webAuthn.js";
    import {UAParser} from "ua-parser-js";
    import Form from "@/components/Form.svelte";
    import WebauthnButton from "~/components/webauthn/WebauthnButton.svelte";
    import localizer from "@/common/i18n.js";

    /**
     * @typedef {Object} Props
     * @property {boolean} [prio]
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props} */
    let { prio = true, children, onBegin, onComplete, onError } = $props();
    let browser = new UAParser();
    let nick = `${browser.getBrowser().name} ${browser.getOS().name}`

    const t = localizer({
        'nb-NO': {
            errRegisterFailed: 'Kunne ikke registrere nøkkel',
            register: 'Registrer passnøkkel',
        },
        'da-DK': {
            errRegisterFailed: 'Kunne ikke registrere nøkkel',
            register: 'Registrer passnøkkel',
        },
    })

    function register(evt) {
        evt.preventDefault();
        if (onBegin) {
            onBegin();
        }
        const form = new FormData(evt.currentTarget);
        performRegistrationCeremony(form.get('nick'))
            .then(() => {
                evt.target.reset();
                if (onComplete) {
                    onComplete();
                }
                window.dispatchEvent(new CustomEvent('aid:webauthnKeyAdded'));
            })
            .catch((error) => {
                if (onError) {
                    onError(error, t('errRegisterFailed'));
                }
                console.error("Error occurred during registration ceremony", error);
            });
    }
</script>

<Form onsubmit={register}>
    <input type="hidden" name="nick" value={nick}>
    <WebauthnButton {prio} logName="webauthn_register">{#if children}{@render children()}{:else}{t('register')}{/if}</WebauthnButton>
</Form>
