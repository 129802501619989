<script>
  import { getWithCredentials } from "~/common/fetchHelpers.js";
  import { onMount, onDestroy } from "svelte";
  import spinner from "@/images/icons/spinner.svg?raw";
  import InlineSVG from "@/components/InlineSVG.svelte";
  import MessageBox from "~/components/MessageBox.svelte";
  import localizer from "@/common/i18n.js";
  /**
   * @typedef {Object} Props
   * @property {string} [credentialUUID]
   * @property {import('svelte').Snippet} [children]
   */

  /** @type {Props} */
  let { credentialUUID = "", children } = $props();
  let status = $state("");

  const t = localizer({
    'nb-NO': {
      errFetchSendStatus: 'Kunne ikke hente status på sending av engangskode. Du kan forsøke å laste siden på nytt.',
      errSendFailed: 'Sending av engangskode feilet. Prøv igjen eller kontakt kundeservice på <a href="mailto:kundeservice@aid.no">kundeservice@aid.no</a>.'
    },
    'da-DK': {
      errFetchSendStatus: 'Kunne ikke hente status på sending af engangskode. Du kan forsøge at genindlæse siden.',
      errSendFailed: 'Sending af engangskode mislykkedes. Prøv igen eller kontakt kundeservice på <a href="mailto:kundeservice@aid.no">kundeservice@aid.no</a>.'
    }
  })

  onMount(() => {
    refreshSendStatus();
  });

  function refreshSendStatus() {
    getWithCredentials(
      `/aid/logg_inn/status_sending_engangskode/${credentialUUID}`,
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(
            new Error(t('errFetchSendStatus')),
          );
        }
        return response.json();
      })
      .then((json) => {
        const updatedStatus = json.transport_status;

        /**
         * If we detect abuse of OTP-service, hold further updates and make
         * the potential miscreant stew for a while.
         */
        if (updatedStatus === "abuse") {
          setTimeout(() => {
            status = "sent"
          }, 60*1000)
          return
        }

        if (!isCompleted(updatedStatus)) {
          setTimeout(refreshSendStatus, 5000);
        }
        status = updatedStatus;
      });
  }

  function isCompleted(status) {
    return status === "sent" || status === "error" || status === "expired";
  }
</script>

{#if status === "sent"}
  {@render children?.()}
{:else if status === "error" || status === "expired"}
  <MessageBox type="error"
    >{@html t('errSendFailed')}</MessageBox
  >
{:else}
  <InlineSVG withRotation={true} svg={spinner} --svg-square-size="24px" />
{/if}
